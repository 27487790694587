<template>
  <div class="">
    <div class="p-0 m-0">
      <b-container>
        <b-card
          no-body
          class="mr-1"
          style="font-size: 20px;"
        >
          <b-card-header class="p-1">
            <h5 style="font-size: 22px;">
              {{ $t("Search Filter") }}
            </h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label style="font-size: 20px;">{{ $t("Designation") }}:</label>
                <b-form-select
                  v-model="role"
                  size="lg"
                  class="form"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label style="font-size: 20px;">{{ $t("Search Name") }}:</label>
                <b-form-input
                  id="icons-search"
                  :placeholder="$t('Search name')"
                  size="lg"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card style="overflow-y: auto;">
          <!-- {{ itemsssss[0] }} -->
          <b-table
            class="styleTable"
            style="font-size: 20px; overflow-y: auto;"
            :items="settingList"
            :fields="fields"
            label-size="lg"
            bordered
          >
            <template #head()="data">
              <div
                class="d-flex justify-content-center"
                style="font-size: 20px;"
              >
                {{ $t(data.label) }}
              </div>
            </template>

            <template #cell(employee_number)="data">
              <div
                class="d-flex justify-content-center"
                style="
                              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 20px;
                              color:#464E5F;
                              "
              >
                {{ data.item.employee_number }}
              </div>
            </template>

            <template #cell(employee_name)="data">
              <div
                class="d-flex justify-content-start"
                style="
                              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 20px;
                              color:#464E5F;
                              "
              >
                {{ data.item.employee_name }}
              </div>
            </template>

            <template #cell(jd_kra)="data">
              <div class="d-flex align-items-center">
                <b-form-radio
                  v-model="data.item.jd_kra"
                  plain
                  :name="'jd'+data.item.jd_kra +data.index"
                  size="lg"
                  :value="0"
                  @change="updateStatusKRA(0, data.item.user_id)"
                >
                  <h1
                    style="
                              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 20px;
                              color:#464E5F;
                              "
                  >JD</h1>
                </b-form-radio>
              </div>
              <div class="d-flex align-items-center">
                <b-form-radio
                  v-model="data.item.jd_kra"
                  plain
                  :name="'jd'+data.item.jd_kra +data.index "
                  size="lg"
                  :value="1"
                  @change="updateStatusKRA(1, data.item.user_id)"
                >
                  <h1
                    style="
                              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 20px;
                              color:#464E5F;
                              "
                  >KRA</h1>
                </b-form-radio>
              </div>
            </template>

            <template #cell(eyes)="">
              <div
                class="d-flex justify-content-center"
                style="
                              font-family: Poppins;
                              font-weight:400;
                              font-style: normal;
                              font-size: 20px;
                              color:#464E5F;
                              "
              >
                <router-link
                  :to="{name:'setting2'}"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                    style="color:black;"
                  />
                </router-link>
              </div>
            </template>

          </b-table>
        </b-card>
      </b-container>
    </div>
  </div>
</template>
<script>
import {
// BAvatar,
  BTable,
  BCard,
  BCardBody,
  BContainer,
  //   BImg,
  // BModal,
  BCol,
  BRow,
  BFormInput,
  //   BFormDatepicker,
  BFormSelect,
  //   BButton,
  BCardHeader,
  BFormRadio,

} from "bootstrap-vue"

export default {
  components: {
    // BAvatar,
    BCard,
    BCardBody,
    BContainer,
    // BImg,
    // BModal,
    BCol,
    BRow,
    BFormInput,
    // BFormDatepicker,
    BFormSelect,
    // BButton,
    BTable,
    BCardHeader,
    BFormRadio,
  },

  data(){
    return {
      role: "",
      selected: null,
      settingList: [],
      fields: [
        {
          key: 'employee_number', label: 'No. Pekerja', thClass: 'text-center',
        },
        {
          key: 'employee_name', label: 'Nama Pekerja', tdClass: 'text-center',
        },
        {
          key: 'employee_position', label: 'Jawatan', tdClass: 'text-center',
        },
        {
          key: 'jd_kra', label: 'setting', tdClass: 'text-center',
        },
        {
          key: 'eyes', label: 'setting2', tdClass: 'text-center',
        },
      ],
    }
  },
  mounted(){
    this.getList()
  },
  methods: {
    getList() {
      this.$axios.get(`${this.$baseUrl}/performancesetting/get_all_setting_form_jdkra`).then(response => {
        this.settingList = response.data.data
      })
    },
    updateStatusKRA(performance, id){
      const data = new FormData()
      data.append('user_id', id)
      data.append('performance_set', performance)

      this.$axios.post(`${this.$baseUrl}/assignPerformance`, data).then(() => {
        // console.log(response.data.data.userSettings[0].user_id)
      })
    },
  },
}
</script>
<style>

</style>
